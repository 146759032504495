// import { isLoggedIn } from '@utils/auth'
import Constants from '@constants'
import {
  requiredNoteRulesApi,
  dialogRulesApi,
  worklogRulesApi,
  assetCustomRulesApi,
} from './api'

function createCustomRulesModule(moduleName) {
  const vuexModule = {
    namespaced: true,
  }
  vuexModule.state = {
    fields: [],
    qualifiedFields: {},
    dialogFields: [],
    qualifiedDialogFields: {},
    worklogAccess: '',
    assetManualUpdateRules: {},
  }

  vuexModule.getters = {
    fields(state) {
      return state.fields
    },
    qualifiedFields(state) {
      return state.qualifiedFields
    },
    fieldRequiresNote(state) {
      return (field) => state.fields.indexOf(field) >= 0
    },
    dialogFields(state) {
      return state.dialogFields
    },
    qualifiedDialogFields(state) {
      return state.qualifiedDialogFields
    },
    fieldDialog(state) {
      return (field) => state.dialogFields.indexOf(field) >= 0
    },
    worklogAccess(state) {
      return state.worklogAccess
    },
    assetHardwareAskForApproval(state) {
      return state.assetManualUpdateRules.askApprovalForHardwareAsset
    },
    assetNonItAskForApproval(state) {
      return state.assetManualUpdateRules.askApprovalForNonITAsset
    },
  }

  vuexModule.mutations = {
    SET_FIELDS(state, fields) {
      state.fields = fields
    },
    SET_QUALIFIED_FIELDS(state, ruleInfo) {
      state.qualifiedFields = ruleInfo
    },
    SET_DIALOG_FIELDS(state, dialogFields) {
      state.dialogFields = dialogFields
    },
    SET_QUALIFIED_DIALOG_FIELDS(state, ruleInfo) {
      state.qualifiedDialogFields = ruleInfo
    },
    SET_WORKLOG_ACCESS_RULE(state, worklogAccess) {
      state.worklogAccess = worklogAccess
    },
    SET_MANUAL_UPDATE_RULES(state, assetManualUpdateRules) {
      state.assetManualUpdateRules = assetManualUpdateRules
    },
  }

  vuexModule.actions = {
    init({ dispatch }) {
      // if (isLoggedIn()) {
      //   dispatch('fetch')
      // }
    },
    fetch({ commit, rootGetters }) {
      const availableModules = rootGetters['license/availableModules']
      if (availableModules.indexOf(moduleName) >= 0) {
        // perform any api request which should be called only when user is logged in
        if (moduleName === Constants.ASSET) {
          assetCustomRulesApi().then((data) => {
            commit('SET_MANUAL_UPDATE_RULES', data)
          })
        } else {
          requiredNoteRulesApi(moduleName).then((data) => {
            commit(
              'SET_FIELDS',
              data.items.map((i) => `${i}`)
            )
            commit('SET_QUALIFIED_FIELDS', data.ruleInfo)
          })

          dialogRulesApi(moduleName).then((data) => {
            commit(
              'SET_DIALOG_FIELDS',
              data.items.map((i) => `${i}`)
            )
            commit('SET_QUALIFIED_DIALOG_FIELDS', data.ruleInfo)
          })

          if (moduleName !== Constants.PURCHASE) {
            worklogRulesApi(moduleName).then((data) => {
              commit('SET_WORKLOG_ACCESS_RULE', data.item)
            })
          }
        }
      }
    },

    fetchDialog({ commit }) {
      // perform any api request which should be called only when user is logged in
      dialogRulesApi(moduleName).then((data) => {
        commit(
          'SET_DIALOG_FIELDS',
          data.items.map((i) => `${i}`)
        )
        commit('SET_QUALIFIED_DIALOG_FIELDS', data.ruleInfo)
      })
    },

    /**
     * destroy all states when user is logged out
     */
    // destroy({ commit }) {
    //   // perform state clean up here when user is logged out
    //   commit('SET_FIELDS', [])
    //   commit('SET_DIALOG_FIELDS', [])
    // },
  }

  return vuexModule
}

export const modules = {
  request: createCustomRulesModule(Constants.REQUEST),
  service_catalog: createCustomRulesModule(Constants.SERVICE_CATALOG),
  problem: createCustomRulesModule(Constants.PROBLEM),
  change: createCustomRulesModule(Constants.CHANGE),
  release: createCustomRulesModule(Constants.RELEASE),
  purchase: createCustomRulesModule(Constants.PURCHASE),
  project: createCustomRulesModule(Constants.PROJECT),
  asset: createCustomRulesModule(Constants.ASSET),
}
